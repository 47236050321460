.image_simulator{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url(../../assets/img/Maskgroup.svg);
    background-repeat: no-repeat;
    background-size: 100%;
} 
