.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.icon_networks {
  display: flex;
  gap: 1rem;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px+2vmin);
  color: #fff;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.section-title {
  text-align: center;
  padding-bottom: 30px;
}
.faq.faq-list {
  padding: 0 100px;
}
.faq.faq-list ul {
  padding: 0;
  list-style: none;
}
.faq.faq-list li + li {
  margin-top: 15px;
}
.faq.faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}
.faq.faq-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}
.faq.faq-list.icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: #76b5ee;
}
.faq.faq-list.icon-show,
.faq.faq-list.icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}
.faq.faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}
.faq.faq-list.icon-show {
  display: none;
}
.faq.faq-list a.collapsed {
  color: #343a40;
}
.faq.faq-list a.collapsed:hover {
  color: #1977cc;
}
.faq.faq-list a.collapsed.icon-show {
  display: inline-block;
}
.faq.faq-list a.collapsed.icon-close {
  display: none;
}
a[data-bs-toggle]i.icon-arrow-collapsed:before {
  content: "\f106";
}
a[data-bs-toggle].collapsed i.icon-arrow-collapsed:before {
  content: "\f107";
}
@media (max-width: 1200px) {
  .faq.faq-list {
    padding: 0;
  }
}
.button_start {
  left: 10% !important;
}

.card-box {
  border: 1px solid #ddd;
  padding: 8px;
  box-shadow: 0 0 10px 0 #c5c5c5;
  margin-bottom: 30px;
  float: left;
  border-radius: 10px;
}
.card-box:hover {
  transform: scale(1.1);
}
.card-box.card-thumbnail {
  max-height: 200px;
  overflow: hidden;
  border-radius: 10px;
  transition: 1s;
}
.card-box h3 a {
  font-size: 20px;
  text-decoration: none;
}
#mainNavigation a {
  font-family: "Cabin", sans-serif;
  font-size: 15px;
  letter-spacing: 0.5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  color: #fff;
}
.dropdown-item {
  color: #fff !important;
}
a.dropdown-toggle {
  color: #dfdfdf !important;
}
a.dropdown-item:hover {
  color: #03727d !important;
}
.nav-item a:hover {
  color: #fff;
}
.nav-item {
  min-width: 12vw;
}
#mainNavigation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 123;
  padding-bottom: 120px;
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.65) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#a6000000',endColorstr='#00000000',GradientType=0);
}
#navbarNavDropdown.collapsing.navbar-nav,
#navbarNavDropdown.show.navbar-nav {
  background: #28a745;
  padding: 12px;
}
.dropdown_hover:hover > .dropdown-menu {
  display: block;
}
.profile-card-2 {
  max-width: 300px;
  background-color: #fff;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
  background-position: center;
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
.profile-card-2 img {
  transition: all linear 0.25s;
}
.profile-card-2 .profile-name {
  position: absolute;
  bottom: 70px;
  font-size: 20px;
  color: #fff;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  transition: all linear 0.25s;
}
.profile-card-2 .profile-username {
  position: absolute;
  bottom: 50px;
  left: 30px;
  color: #fff;
  font-size: 13px;
  transition: all linear 0.25s;
}
.profile-card-2:hover img {
  filter: grayscale(100%);
}
.profile-card-2:hover.profile-name {
  bottom: 80px;
}
.profile-card-2:hover.profile-username {
  bottom: 60px;
}
.msg-erro {
  color: red;
}
.input-group-Text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  width: 4.5rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-Text_fgts {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  width: 7rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.text-justify {
  text-align: justify !important;
}
.page-Section {
  padding: 50px 0;
  background: -webkit-linear-gradient(
    180deg,
    rgba(48, 105, 95, 1) 0%,
    rgba(85, 137, 79, 1) 100%
  );
  background: linear-gradient(
    180deg,
    rgba(48, 105, 95, 1) 0%,
    rgba(85, 137, 79, 1) 100%
  );
  color: #fff;
  font-size: 13.5px;
}
.img_width {
  width: 1rem;
  margin-top: -2px;
}
.color_white {
  color: #fff !important;
}
.Profile-card-2 {
  max-width: 100%;
  background-color: #fff;
  box-shadow: 0 0 25px rgb(0 0 0/10%);
  background-position: center;
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
.profile-card-3 {
  max-width: 100%;
  background-color: #fff;
  background-position: center;
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}
.Profile-card-2 .profile-name {
  position: absolute;
  bottom: 70px;
  font-size: 20px;
  color: #fff;
  text-shadow: 0 0 20px rgb(0 0 0/50%);
  transition: all linear 0.25s;
}
.border_radius {
  border: 2px solid #1cc600;
  border-radius: 15px;
}
.color_sobre {
  color: #142860;
}
.border_radius_btn {
  border-radius: 5rem !important;
}
.white_link {
  color: #fff;
}
.hover_products:hover {
  background-color: #fff !important;
  color: #198754 !important;
}
#saque-aniversario-secao-01 b {
  font-size: 48px;
  line-height: 10px;
  color: #1cc600;
  left: 0;
}
@media (min-width: 768px) {
  .principal-card {
    height: 70vh;
  }
  .height_card {
    height: 375px;
  }
}
#alerta-politica-privacidade {
  background-color: #1cc600;
  padding: 0;
  margin: 0;
  color: #fff !important;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 26px;
  text-transform: none;
  text-decoration: none !important;
}
#alerta-politica-privacidade.btn:hover {
  background-color: #235e4e;
  color: #fff;
}
#alerta-politica-privacidade {
  position: fixed;
  bottom: 0;
  background: #6e6e6e;
  z-index: 999;
  -webkit-transition: bottom 0.2s ease-in-out;
  transition: bottom 0.2s ease-in-out;
}
#alerta-politica-privacidade.btn {
  line-height: unset;
  font-size: 15px;
  padding: 3px 15px;
}
#alerta-politica-privacidade p {
  font-size: 15px;
  line-height: 15px;
}
#alerta-politica-privacidade button {
  font-size: 15px;
}
@media (min-width: 992px) {
  header.masthead .intro-text {
    padding-top: 210px !important;
    padding-bottom: 90px !important;
  }
}
@media (max-width: 915px) {
  .margin_top_home {
    margin-top: 13rem;
    margin-bottom: 2rem;
  }
  .carousel-indicators [data-bs-target] {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 5px;
    background-color: #198754;
    border-radius: 50%;
    border: 1.5px solid #fff;
    margin-top: -1.5rem !important;
  }

  /* .margin_top_smm {
    margin-top: 2rem !important;
  } */
  
  
  .button_start {
    left: 15% !important;
  }
  .logo_width_mobile {
    width: 40px !important;
  }

  .font_size_sm {
    font-size: 23px;
  }
  .text_left_sm {
    text-align: left;
    margin-left: 1.2rem !important;
  }
  header.masthead {
    color: #142860;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center;
    /* background-size: 100% !important; */
    background-size: contain !important;
  }
  .font_size_sm_h3 {
    font-size: 16px;
  }
  .intro-lead-in {
    font-size: 16px !important;
  }
  .mainNavigation {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 123;
    padding-bottom: 120px;
    background: none !important;
  }
  .height_card {
    height: 375px;
  }
  .profile-card-2 {
    max-width: 64%;
    background-color: #fff;
    box-shadow: 0 0 25px rgb(0 0 0/10%);
    background-position: center;
    overflow: hidden;
    position: relative;
    margin: 10px auto;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    justify-content: center;
  }
  .btn_sm {
    margin-top: 3rem;
    width: 90px;
  }
  .profile-card-3 {
    background-color: #fff;
    background-position: center;
    overflow: hidden;
    position: relative;
    margin: 10px auto;
    cursor: pointer;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    width: 326px;
  }
  .font_size_titulo {
    font-size: 24px;
  }
  .font_size_subtitulo {
    font-size: 20px;
  }
  .margin_top_sm {
    margin-top: 3.5rem;
  }
  /* .margin_top_smm {
    margin-top: 0.5rem;
  }
  */
  .margin_top_precisando {
    margin-top: 2rem;
  }
  .top_position {
    top: 41rem;
  }
  .border_radius {
    width: 100%;
  }
  .height_sm {
    height: 130px;
  }
  .height_sm_burocracia {
    height: 200px;
  }
  .margin_sm_top {
    margin-top: 0.5rem;
  }
  .height_card_sm {
    height:     auto;
    margin-top: 1rem;
  }
  .margin_top_sm_card {
    height: 240px;
  }
  .height_img_sm {
    height: 100%;
  }
  .margin_bottom_card {
    margin-bottom: 1rem;
  }
  .font_size_parceiros {
    font-size: 0.6rem !important;
  }
  .margin_bottom_estate {
    margin-bottom: 3rem;
  }
  .play_margin_top {
    margin-top: 3rem;
  }
}
.top_position {
  top: 41rem;
}
.translate-middle {
  transform: translate(-50%, -267%) !important;
}
.font_size_parceiros_lg {
  font-size: 0.7rem;
  text-align: center;
}
.margi_top_simule {
  margin-top: 13rem;
}
.text_left_lg {
  text-align: left;
  margin-left: 8.2rem;
}
.page-fraud {
  padding: 50px 0;
  background: rgba(48, 105, 95, 1) 0%;
  color: #fff;
  font-size: 12px;
}
.logo_width {
  width: 7rem;
}
.rangers,
.input-range {
  display: flex;
  align-items: center;
}
.rangers {
  flex-direction: row;
  gap: 0;
}
.rangers span {
  font-size: 0.885rem;
  font-weight: 400;
}
.rangers input {
  padding: 0 !important;
}
.input-range {
  gap: 10px;
}
.input-range input {
  padding: 4px;
  outline: 0;
}
.input-range input[type="range"],
.input-range input[type="text"] {
  background: transparent;
}
.input-range input[type="range"] {
  width: 146px;
  height: 30px;
  overflow: hidden;
}
.input-range input[type="text"],
.input-item-selected {
  color: #198754;
}
.input-range input[type="text"],
.input-item-selected,
.text-muted {
  font-weight: 600;
}
.input-range input[type="text"] {
  border: 0;
}
input[type="range"] {
  -webkit-appearance: none;
}
input::-webkit-slider-runnable-track {
  content: "";
  height: 10px;
  background: #c7c7c7;
  border-radius: 10px;
}
input#max::-webkit-slider-runnable-track {
  content: "";
  height: 10px;
  background: #224386;
  border-radius: 10px;
}
input#max::-webkit-slider-thumb {
  height: 28px;
  width: 28px;
  -webkit-appearance: none;
  appearance: none;
  background: #198754;
  border-radius: 10px;
  box-shadow: 5px 0 0-8px #c7c7c7, 6px 0 0-8px #c7c7c7, 7px 0 0-8px #c7c7c7,
    8px 0 0-8px #c7c7c7, 9px 0 0-8px #c7c7c7, 10px 0 0-8px #c7c7c7,
    11px 0 0-8px #c7c7c7, 12px 0 0-8px #c7c7c7, 13px 0 0-8px #c7c7c7,
    14px 0 0-8px #c7c7c7, 15px 0 0-8px #c7c7c7, 16px 0 0-8px #c7c7c7,
    17px 0 0-8px #c7c7c7, 18px 0 0-8px #c7c7c7, 19px 0 0-8px #c7c7c7,
    20px 0 0-8px #c7c7c7, 21px 0 0-8px #c7c7c7, 22px 0 0-8px #c7c7c7,
    23px 0 0-8px #c7c7c7, 24px 0 0-8px #c7c7c7, 25px 0 0-8px #c7c7c7,
    26px 0 0-8px #c7c7c7, 27px 0 0-8px #c7c7c7, 28px 0 0-8px #c7c7c7,
    29px 0 0-8px #c7c7c7, 30px 0 0-8px #c7c7c7, 31px 0 0-8px #c7c7c7,
    32px 0 0-8px #c7c7c7, 33px 0 0-8px #c7c7c7, 34px 0 0-8px #c7c7c7,
    35px 0 0-8px #c7c7c7, 36px 0 0-8px #c7c7c7, 37px 0 0-8px #c7c7c7,
    38px 0 0-8px #c7c7c7, 39px 0 0-8px #c7c7c7, 40px 0 0-8px #c7c7c7,
    41px 0 0-8px #c7c7c7, 42px 0 0-8px #c7c7c7, 43px 0 0-8px #c7c7c7,
    44px 0 0-8px #c7c7c7, 45px 0 0-8px #c7c7c7, 46px 0 0-8px #c7c7c7,
    47px 0 0-8px #c7c7c7, 48px 0 0-8px #c7c7c7, 49px 0 0-8px #c7c7c7,
    50px 0 0-8px #c7c7c7, 51px 0 0-8px #c7c7c7, 52px 0 0-8px #c7c7c7,
    53px 0 0-8px #c7c7c7, 54px 0 0-8px #c7c7c7, 55px 0 0-8px #c7c7c7,
    56px 0 0-8px #c7c7c7, 57px 0 0-8px #c7c7c7, 58px 0 0-8px #c7c7c7,
    59px 0 0-8px #c7c7c7, 60px 0 0-8px #c7c7c7, 61px 0 0-8px #c7c7c7,
    62px 0 0-8px #c7c7c7, 63px 0 0-8px #c7c7c7, 64px 0 0-8px #c7c7c7,
    65px 0 0-8px #c7c7c7, 66px 0 0-8px #c7c7c7, 67px 0 0-8px #c7c7c7,
    68px 0 0-8px #c7c7c7, 69px 0 0-8px #c7c7c7, 70px 0 0-8px #c7c7c7,
    71px 0 0-8px #c7c7c7, 72px 0 0-8px #c7c7c7, 73px 0 0-8px #c7c7c7,
    74px 0 0-8px #c7c7c7, 75px 0 0-8px #c7c7c7, 76px 0 0-8px #c7c7c7,
    77px 0 0-8px #c7c7c7, 78px 0 0-8px #c7c7c7, 79px 0 0-8px #c7c7c7,
    80px 0 0-8px #c7c7c7, 81px 0 0-8px #c7c7c7, 82px 0 0-8px #c7c7c7,
    83px 0 0-8px #c7c7c7, 84px 0 0-8px #c7c7c7, 85px 0 0-8px #c7c7c7,
    86px 0 0-8px #c7c7c7, 87px 0 0-8px #c7c7c7, 88px 0 0-8px #c7c7c7,
    89px 0 0-8px #c7c7c7, 90px 0 0-8px #c7c7c7, 91px 0 0-8px #c7c7c7,
    92px 0 0-8px #c7c7c7, 93px 0 0-8px #c7c7c7, 94px 0 0-8px #c7c7c7,
    95px 0 0-8px #c7c7c7, 96px 0 0-8px #c7c7c7, 97px 0 0-8px #c7c7c7,
    98px 0 0-8px #c7c7c7, 99px 0 0-8px #c7c7c7, 100px 0 0-8px #c7c7c7,
    101px 0 0-8px #c7c7c7, 102px 0 0-8px #c7c7c7, 103px 0 0-8px #c7c7c7,
    104px 0 0-8px #c7c7c7, 105px 0 0-8px #c7c7c7, 106px 0 0-8px #c7c7c7,
    107px 0 0-8px #c7c7c7, 108px 0 0-8px #c7c7c7, 109px 0 0-8px #c7c7c7,
    110px 0 0-8px #c7c7c7, 111px 0 0-8px #c7c7c7, 112px 0 0-8px #c7c7c7,
    113px 0 0-8px #c7c7c7, 114px 0 0-8px #c7c7c7, 115px 0 0-8px #c7c7c7,
    116px 0 0-8px #c7c7c7, 117px 0 0-8px #c7c7c7, 118px 0 0-8px #c7c7c7,
    119px 0 0-8px #c7c7c7, 120px 0 0-8px #c7c7c7, 121px 0 0-8px #c7c7c7,
    122px 0 0-8px #c7c7c7, 123px 0 0-8px #c7c7c7, 124px 0 0-8px #c7c7c7,
    125px 0 0-8px #c7c7c7, 126px 0 0-8px #c7c7c7, 127px 0 0-8px #c7c7c7,
    128px 0 0-8px #c7c7c7, 129px 0 0-8px #c7c7c7, 130px 0 0-8px #c7c7c7,
    131px 0 0-8px #c7c7c7, 132px 0 0-8px #c7c7c7, 133px 0 0-8px #c7c7c7,
    134px 0 0-8px #c7c7c7, 135px 0 0-8px #c7c7c7, 136px 0 0-8px #c7c7c7,
    137px 0 0-8px #c7c7c7, 138px 0 0-8px #c7c7c7, 139px 0 0-8px #c7c7c7,
    140px 0 0-8px #c7c7c7, 141px 0 0-8px #c7c7c7, 142px 0 0-8px #c7c7c7,
    143px 0 0-8px #c7c7c7, 144px 0 0-8px #c7c7c7, 145px 0 0-8px #c7c7c7,
    146px 0 0-8px #c7c7c7, 147px 0 0-8px #c7c7c7, 148px 0 0-8px #c7c7c7,
    149px 0 0-8px #c7c7c7, 150px 0 0-8px #c7c7c7, 151px 0 0-8px #c7c7c7,
    152px 0 0-8px #c7c7c7, 153px 0 0-8px #c7c7c7, 154px 0 0-8px #c7c7c7,
    155px 0 0-8px #c7c7c7, 156px 0 0-8px #c7c7c7, 157px 0 0-8px #c7c7c7,
    158px 0 0-8px #c7c7c7, 159px 0 0-8px #c7c7c7, 160px 0 0-8px #c7c7c7,
    161px 0 0-8px #c7c7c7, 162px 0 0-8px #c7c7c7, 163px 0 0-8px #c7c7c7,
    164px 0 0-8px #c7c7c7, 165px 0 0-8px #c7c7c7, 166px 0 0-8px #c7c7c7,
    167px 0 0-8px #c7c7c7, 168px 0 0-8px #c7c7c7, 169px 0 0-8px #c7c7c7,
    170px 0 0-8px #c7c7c7, 171px 0 0-8px #c7c7c7, 172px 0 0-8px #c7c7c7,
    173px 0 0-8px #c7c7c7, 174px 0 0-8px #c7c7c7, 175px 0 0-8px #c7c7c7,
    176px 0 0-8px #c7c7c7, 177px 0 0-8px #c7c7c7, 178px 0 0-8px #c7c7c7,
    179px 0 0-8px #c7c7c7, 180px 0 0-8px #c7c7c7, 181px 0 0-8px #c7c7c7,
    182px 0 0-8px #c7c7c7, 183px 0 0-8px #c7c7c7, 184px 0 0-8px #c7c7c7,
    185px 0 0-8px #c7c7c7, 186px 0 0-8px #c7c7c7, 187px 0 0-8px #c7c7c7,
    188px 0 0-8px #c7c7c7, 189px 0 0-8px #c7c7c7, 190px 0 0-8px #c7c7c7,
    191px 0 0-8px #c7c7c7, 192px 0 0-8px #c7c7c7, 193px 0 0-8px #c7c7c7,
    194px 0 0-8px #c7c7c7, 195px 0 0-8px #c7c7c7, 196px 0 0-8px #c7c7c7,
    197px 0 0-8px #c7c7c7, 198px 0 0-8px #c7c7c7, 199px 0 0-8px #c7c7c7,
    200px 0 0-8px #c7c7c7, 201px 0 0-8px #c7c7c7, 202px 0 0-8px #c7c7c7,
    203px 0 0-8px #c7c7c7, 204px 0 0-8px #c7c7c7, 205px 0 0-8px #c7c7c7,
    206px 0 0-8px #c7c7c7, 207px 0 0-8px #c7c7c7, 208px 0 0-8px #c7c7c7,
    209px 0 0-8px #c7c7c7, 210px 0 0-8px #c7c7c7, 211px 0 0-8px #c7c7c7,
    212px 0 0-8px #c7c7c7, 213px 0 0-8px #c7c7c7, 214px 0 0-8px #c7c7c7,
    215px 0 0-8px #c7c7c7, 216px 0 0-8px #c7c7c7, 217px 0 0-8px #c7c7c7,
    218px 0 0-8px #c7c7c7, 219px 0 0-8px #c7c7c7, 220px 0 0-8px #c7c7c7,
    221px 0 0-8px #c7c7c7, 222px 0 0-8px #c7c7c7, 223px 0 0-8px #c7c7c7,
    224px 0 0-8px #c7c7c7, 225px 0 0-8px #c7c7c7, 226px 0 0-8px #c7c7c7,
    227px 0 0-8px #c7c7c7, 228px 0 0-8px #c7c7c7, 229px 0 0-8px #c7c7c7,
    230px 0 0-8px #c7c7c7, 231px 0 0-8px #c7c7c7, 232px 0 0-8px #c7c7c7,
    233px 0 0-8px #c7c7c7, 234px 0 0-8px #c7c7c7, 235px 0 0-8px #c7c7c7,
    236px 0 0-8px #c7c7c7, 237px 0 0-8px #c7c7c7, 238px 0 0-8px #c7c7c7,
    239px 0 0-8px #c7c7c7, 240px 0 0-8px #c7c7c7;
}
input::-webkit-slider-thumb {
  height: 28px;
  width: 28px;
  -webkit-appearance: none;
  appearance: none;
  background: #6bb618;
  border-radius: 10px;
  box-shadow: 5px 0 0-8px #224386, 6px 0 0-8px #224386, 7px 0 0-8px #224386,
    8px 0 0-8px #224386, 9px 0 0-8px #224386, 10px 0 0-8px #224386,
    11px 0 0-8px #224386, 12px 0 0-8px #224386, 13px 0 0-8px #224386,
    14px 0 0-8px #224386, 15px 0 0-8px #224386, 16px 0 0-8px #224386,
    17px 0 0-8px #224386, 18px 0 0-8px #224386, 19px 0 0-8px #224386,
    20px 0 0-8px #224386, 21px 0 0-8px #224386, 22px 0 0-8px #224386,
    23px 0 0-8px #224386, 24px 0 0-8px #224386, 25px 0 0-8px #224386,
    26px 0 0-8px #224386, 27px 0 0-8px #224386, 28px 0 0-8px #224386,
    29px 0 0-8px #224386, 30px 0 0-8px #224386, 31px 0 0-8px #224386,
    32px 0 0-8px #224386, 33px 0 0-8px #224386, 34px 0 0-8px #224386,
    35px 0 0-8px #224386, 36px 0 0-8px #224386, 37px 0 0-8px #224386,
    38px 0 0-8px #224386, 39px 0 0-8px #224386, 40px 0 0-8px #224386,
    41px 0 0-8px #224386, 42px 0 0-8px #224386, 43px 0 0-8px #224386,
    44px 0 0-8px #224386, 45px 0 0-8px #224386, 46px 0 0-8px #224386,
    47px 0 0-8px #224386, 48px 0 0-8px #224386, 49px 0 0-8px #224386,
    50px 0 0-8px #224386, 51px 0 0-8px #224386, 52px 0 0-8px #224386,
    53px 0 0-8px #224386, 54px 0 0-8px #224386, 55px 0 0-8px #224386,
    56px 0 0-8px #224386, 57px 0 0-8px #224386, 58px 0 0-8px #224386,
    59px 0 0-8px #224386, 60px 0 0-8px #224386, 61px 0 0-8px #224386,
    62px 0 0-8px #224386, 63px 0 0-8px #224386, 64px 0 0-8px #224386,
    65px 0 0-8px #224386, 66px 0 0-8px #224386, 67px 0 0-8px #224386,
    68px 0 0-8px #224386, 69px 0 0-8px #224386, 70px 0 0-8px #224386,
    71px 0 0-8px #224386, 72px 0 0-8px #224386, 73px 0 0-8px #224386,
    74px 0 0-8px #224386, 75px 0 0-8px #224386, 76px 0 0-8px #224386,
    77px 0 0-8px #224386, 78px 0 0-8px #224386, 79px 0 0-8px #224386,
    80px 0 0-8px #224386, 81px 0 0-8px #224386, 82px 0 0-8px #224386,
    83px 0 0-8px #224386, 84px 0 0-8px #224386, 85px 0 0-8px #224386,
    86px 0 0-8px #224386, 87px 0 0-8px #224386, 88px 0 0-8px #224386,
    89px 0 0-8px #224386, 90px 0 0-8px #224386, 91px 0 0-8px #224386,
    92px 0 0-8px #224386, 93px 0 0-8px #224386, 94px 0 0-8px #224386,
    95px 0 0-8px #224386, 96px 0 0-8px #224386, 97px 0 0-8px #224386,
    98px 0 0-8px #224386, 99px 0 0-8px #224386, 100px 0 0-8px #224386,
    101px 0 0-8px #224386, 102px 0 0-8px #224386, 103px 0 0-8px #224386,
    104px 0 0-8px #224386, 105px 0 0-8px #224386, 106px 0 0-8px #224386,
    107px 0 0-8px #224386, 108px 0 0-8px #224386, 109px 0 0-8px #224386,
    110px 0 0-8px #224386, 111px 0 0-8px #224386, 112px 0 0-8px #224386,
    113px 0 0-8px #224386, 114px 0 0-8px #224386, 115px 0 0-8px #224386,
    116px 0 0-8px #224386, 117px 0 0-8px #224386, 118px 0 0-8px #224386,
    119px 0 0-8px #224386, 120px 0 0-8px #224386, 121px 0 0-8px #224386,
    122px 0 0-8px #224386, 123px 0 0-8px #224386, 124px 0 0-8px #224386,
    125px 0 0-8px #224386, 126px 0 0-8px #224386, 127px 0 0-8px #224386,
    128px 0 0-8px #224386, 129px 0 0-8px #224386, 130px 0 0-8px #224386,
    131px 0 0-8px #224386, 132px 0 0-8px #224386, 133px 0 0-8px #224386,
    134px 0 0-8px #224386, 135px 0 0-8px #224386, 136px 0 0-8px #224386,
    137px 0 0-8px #224386, 138px 0 0-8px #224386, 139px 0 0-8px #224386,
    140px 0 0-8px #224386, 141px 0 0-8px #224386, 142px 0 0-8px #224386,
    143px 0 0-8px #224386, 144px 0 0-8px #224386, 145px 0 0-8px #224386,
    146px 0 0-8px #224386, 147px 0 0-8px #224386, 148px 0 0-8px #224386,
    149px 0 0-8px #224386, 150px 0 0-8px #224386, 151px 0 0-8px #224386,
    152px 0 0-8px #224386, 153px 0 0-8px #224386, 154px 0 0-8px #224386,
    155px 0 0-8px #224386, 156px 0 0-8px #224386, 157px 0 0-8px #224386,
    158px 0 0-8px #224386, 159px 0 0-8px #224386, 160px 0 0-8px #224386,
    161px 0 0-8px #224386, 162px 0 0-8px #224386, 163px 0 0-8px #224386,
    164px 0 0-8px #224386, 165px 0 0-8px #224386, 166px 0 0-8px #224386,
    167px 0 0-8px #224386, 168px 0 0-8px #224386, 169px 0 0-8px #224386,
    170px 0 0-8px #224386, 171px 0 0-8px #224386, 172px 0 0-8px #224386,
    173px 0 0-8px #224386, 174px 0 0-8px #224386, 175px 0 0-8px #224386,
    176px 0 0-8px #224386, 177px 0 0-8px #224386, 178px 0 0-8px #224386,
    179px 0 0-8px #224386, 180px 0 0-8px #224386, 181px 0 0-8px #224386,
    182px 0 0-8px #224386, 183px 0 0-8px #224386, 184px 0 0-8px #224386,
    185px 0 0-8px #224386, 186px 0 0-8px #224386, 187px 0 0-8px #224386,
    188px 0 0-8px #224386, 189px 0 0-8px #224386, 190px 0 0-8px #224386,
    191px 0 0-8px #224386, 192px 0 0-8px #224386, 193px 0 0-8px #224386,
    194px 0 0-8px #224386, 195px 0 0-8px #224386, 196px 0 0-8px #224386,
    197px 0 0-8px #224386, 198px 0 0-8px #224386, 199px 0 0-8px #224386,
    200px 0 0-8px #224386, 201px 0 0-8px #224386, 202px 0 0-8px #224386,
    203px 0 0-8px #224386, 204px 0 0-8px #224386, 205px 0 0-8px #224386,
    206px 0 0-8px #224386, 207px 0 0-8px #224386, 208px 0 0-8px #224386,
    209px 0 0-8px #224386, 210px 0 0-8px #224386, 211px 0 0-8px #224386,
    212px 0 0-8px #224386, 213px 0 0-8px #224386, 214px 0 0-8px #224386,
    215px 0 0-8px #224386, 216px 0 0-8px #224386, 217px 0 0-8px #224386,
    218px 0 0-8px #224386, 219px 0 0-8px #224386, 220px 0 0-8px #224386,
    221px 0 0-8px #224386, 222px 0 0-8px #224386, 223px 0 0-8px #224386,
    224px 0 0-8px #224386, 225px 0 0-8px #224386, 226px 0 0-8px #224386,
    227px 0 0-8px #224386, 228px 0 0-8px #224386, 229px 0 0-8px #224386,
    230px 0 0-8px #224386, 231px 0 0-8px #224386, 232px 0 0-8px #224386,
    233px 0 0-8px #224386, 234px 0 0-8px #224386, 235px 0 0-8px #224386,
    236px 0 0-8px #224386, 237px 0 0-8px #224386, 238px 0 0-8px #224386,
    239px 0 0-8px #224386, 240px 0 0-8px #224386;
  margin-top: -8px;
}
input::-moz-range-track {
  width: 240px;
  height: 2px;
}
input::-moz-range-thumb {
  height: 18px;
  width: 28px;
  background: #fff;
  border-radius: 8px;
  position: relative;
}
input::-moz-range-progress,
input::-ms-track {
  border: 0;
}
input#max::-moz-range-progress {
  height: 4px;
  background: #224386;
  border-radius: 10px;
  margin-top: 0;
}
input::-moz-range-progress {
  height: 4px;
  background: #c7c7c7;
  border-radius: 10px;
  margin-top: 0;
}
input::-ms-track {
  width: 400px;
  height: 2px;
  background: transparent;
  border-color: transparent;
  border-radius: 0;
  border-width: 0;
  color: transparent;
  margin-top: 10px;
}
input::-ms-thumb {
  height: 18px;
  width: 28px;
  background: #fff;
  border-radius: 8px;
}
input::-ms-fill-lower,
input::-ms-fill-upper {
  border-radius: 0;
}
input::-ms-fill-lower {
  background: #e33d44;
}
input::-ms-fill-upper {
  background: #c7c7c7;
}
input#max::-ms-fill-upper {
  background: #224386;
}
.tooltip-hide {
  display: none;
}
.tooltip-show {
  display: flex;
}
.margin_top_vehicle {
  margin-top: 2px;
}
.max_width_vehicle {
  max-width: 25rem;
}
.gap_vehicle {
  gap: 1rem;
}
.margin_top_lg {
  margin-top: 11rem;
}
.section-main-numbers bg-numbers {
  background-image: url(https://img.freepik.com/fotos-gratis/a-palavra-aleatoria-soletrada-com-letras-de-madeira_23-2148227685.jpg?size=626&ext=jpg);
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
  padding-top: 20px;
}
.card_imovel {
  /* box-shadow: 5px 5px 13px 1px gray; */
  width: 10rem;
}
.card_veiculo {
  /* box-shadow: 5px 5px 13px 1px gray; */
  width: 11rem;
}
.font_size_cosignado {
  font-size: 1.3rem;
}
.text_yellow {
  color: #ffd862;
}
.btn-success {
  color: #fff;
  background-color: #198754 !important;
  border-color: #198754 !important;
}

.text_custom {
  font-size: 1rem;
  text-align: initial;
  word-spacing: -1px;
}
.channel_top {
  margin-top: 15rem;
}

.doubt_margin_right {
  margin-right: -8px;
}
.dropdown-toggle::after {
  color: white !important;
}

.carousel-indicators [data-bs-target] {
  width: 12px;
  height: 12px;
  padding: 0;
  margin: 5px;
  background-color: #198754;
  border-radius: 50%;
  border: 1.5px solid #fff;
  margin-top: -4.2rem;
}
/* Estilos para o botão */
.IconLauncher__BaseLauncher-wrpu21-0 {
  background-color: rgb(103, 50, 209);
  border-radius: 50%;
  top: 95%;
  width: 3.5em;
  right: 1.5rem;
  padding: 0.8rem 0.8rem;
  margin-top: -2.5em;
  cursor: pointer;
  border: none;
  z-index: 999;
  position: fixed;
}
.card-container-landingpage {
  height: 100%;
}
.card-container-landingpageee {
  height: 98%;
}

.font_size_esclarecimento {
  font-size: 14px;
}
/* Estilos para o botão flutuante do WhatsApp */
.whatsapp-float {
  position: fixed;
  border-radius: 50%;
  top: 85%;
  width: 4em;
  right: 1.5rem;
  padding: 1rem 1rem;
  margin-top: -2.5em;
  cursor: pointer;
  border: none;
  z-index: 999;
  position: fixed;
  background-color: #fff;
  color: #fff;
}

.whatsapp-float:hover {
  background-color: #198754;
  text-decoration: none;
}

.my-float {
  margin-top: 16px;
}

/* Estilos para o ícone dentro do botão (ajuste conforme necessário) */

/* #menu {
  position: fixed;
  background-color: rgb(103, 50, 209);
  border-radius: 50%;
  right: 0;
  top: 50%;
  width: 3em;
  margin-top: -2.5em;
  z-index: 999; 
} */
